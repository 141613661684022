<template>
  <div class="ore-appearances view">
    <app-header title="Ingame - Ore Appearances"/>
    <progress-linear v-if="loading" :indeterminate="true"/>
    <div class="control-bar" :class="{'control-bar--disabled': loading}">
      <div class="control-bar__buttons">
        <div class="control-bar__button" :title="'Tour'" @click="startTour" v-slashes>
          <img class="control-bar__button-icon" src="https://cdn.hyperion-corporation.de/ui/svgs/tour.svg"
               alt="report"/>
        </div>
        <div class="control-bar__button" :title="__('reportOreAppearance')" @click="showReportOreAppearance" v-slashes
             id="report">
          <img class="control-bar__button-icon" src="https://cdn.hyperion-corporation.de/ui/svgs/report.svg"
               alt="report"/>
        </div>
        <div class="control-bar__button" :title="__('common.refresh')" @click="refresh" v-slashes>
          <img class="control-bar__button-icon" src="https://cdn.hyperion-corporation.de/ui/svgs/refresh.svg"
               alt="refresh"/>
        </div>
      </div>
    </div>
    <div class="main-content-padding">
      <div class="scroll-horizontal-overflow">
        <div class="ore-appearance-table grid-table"
             :style="{'grid-template-columns': `auto ${ores.map(ore => '1fr').join(' ')}`}"
        >
            <div class="grid-table__cell grid-table__cell--sticky"></div>
            <div v-for="(ore, index) in ores"
                 :id="`ore-${index}`"
                 class="text--center grid-table__cell grid-table__cell--clickable grid-table__cell--sticky"
                 :class="{'grid-table__cell--highlighted': isOreHighlighted(ore)}"
                 :style="{'border-bottom': `5px solid ${ore.scannerColor}`}"
                 @click="toggleHighlightedOre(ore)"
                 v-slashes
            >
              <img :src="ore.icon"/>
              <div v-html="renderWithTierAndSize(ore.name.replace(' Ore','').replace(' Nuggets',''), ore.scale, ore.tier)"></div>
              <div class="skewed-ribbon skewed-ribbon--large skewed-ribbon--absolute">
                <div class="skewed-ribbon__item skewed-ribbon__item--info" :title="'Tier'">{{ ore.tier ? ore.tier
                  : '-' }}
                </div>
              </div>
            </div>

            <template v-for="(stellarObject, index) in filteredStellarObjects">
              <div class="grid-table__cell grid-table__cell--clickable grid-table__cell--sticky-left"
                   :id="`stellar-object-${index}`"
                   :class="{'grid-table__cell--highlighted': isStellarObjectHighlighted(stellarObject)}"
                   :style="{'min-width': '215px'}"
                   @click="toggleHighlightedStellarObject(stellarObject)"
                   v-slashes
              >
                <div class="labeled-icon">
                  <img class="labeled-icon__img" :src="stellarObject.icon"/>
                  <span class="labeled-icon__label">{{ stellarObject.name }}</span>
                  <span class="labeled-icon__button" :id="`stellar-object-info-${index}`"
                        @click="showStellarObject($event, stellarObject)"><icon
                      :icon="'info'"/></span>
                </div>
              </div>
              <div v-for="ore in ores"
                   class="grid-table__cell text--center"
                   :title="ore.name"
                   :class="{'grid-table__cell--highlighted': isStellarObjectHighlighted(stellarObject) || isOreHighlighted(ore)}"
              >
                <template v-if="renderOreAppearance(stellarObject, ore)">
                  {{ renderOreAppearance(stellarObject, ore).highestAltitudeFromSurface }}
                  <br/>
                  {{ renderOreAppearance(stellarObject, ore).lowestAltitudeFromSurface }}
                </template>
              </div>
            </template>

        </div>
      </div>
    </div>
    <edit-ore-appearance-report ref="editOreAppearanceReport" @created="sayThankYouForOreAppearanceReport"/>
    <my-aside :show="stellarObject !== null" @close="stellarObject = null" :max-width="'600px'">
      <StellarObjectDetails :stellar-object="stellarObject"/>
    </my-aside>
    <v-tour name="oreAppearances" :steps="tour.steps" :options="tour.options" :callbacks="tour.callbacks"/>
  </div>
</template>

<script>
  import apiSecured from '@/api/secured';
  import AppHeader from '@/components/AppHeader';
  import { notificationsMixin } from '@/mixins/notifications';
  import { localizationMixin } from '@/mixins/localization';
  import { pageTitleMixin } from "@/mixins/pageTitle";
  import EditOreAppearanceReport from "@/components/Ingame/EditOreAppearanceReport";
  import Swal from "sweetalert2";
  import { renderWithTierAndSize } from "@/helpers/itemHelpers";
  import StellarObjectDetails from "@/components/Ingame/StellarObject/StellarObjectDetails";

  const tourStepParams = {
    placement: 'bottom',
    highlight: true,
    enableScrolling: false
  };

  export default {
    mixins: [notificationsMixin, localizationMixin, pageTitleMixin, localizationMixin],
    components: {
      StellarObjectDetails,
      EditOreAppearanceReport,
      appHeader: AppHeader
    },
    data: () => ({
      loading: false,
      ores: [],
      stellarObjects: [],
      oreAppearances: [],
      highlightedStellarObjects: [],
      highlightedOres: [],
      stellarObject: null,
      tour: {
        options: {},
        steps: [],
        callbacks: {}
      }
    }),
    computed: {
      filteredStellarObjects() {
        if (this.highlightedOres.length === 0) {
          return this.stellarObjects;
        }
        return this.stellarObjects.filter(stellarObject => {
          let matches = false;
          this.oreAppearances.filter(oreAppearance => oreAppearance.stellarObjectId === stellarObject.id).forEach(oreAppearance => {
            if (this.highlightedOres.includes(oreAppearance.oreId)) {
              matches = true;
            }
          });
          return matches;
        })
      }
    },
    methods: {
      renderWithTierAndSize,
      isTourRunning() {
        return this.$tours['oreAppearances'] && this.$tours['oreAppearances'].isRunning;
      },
      startTour() {
        if (this.isTourRunning()) {
          return;
        }
        this.highlightedOres = [];
        this.highlightedStellarObjects = [];
        this.$tours['oreAppearances'].start();
      },
      showReportOreAppearance() {
        if (this.isTourRunning()) {
          return;
        }
        this.$refs.editOreAppearanceReport.showCreate();
      },
      sayThankYouForOreAppearanceReport() {
        this.$refs.editOreAppearanceReport.close();
        Swal.fire({
          title: this.__('thankYou'),
          html: this.__('sayThankYouForOreAppearanceReport').replace('$user', this.$store.state.user.username),
          type: 'success',
        });
      },
      showStellarObject(event, stellarObject) {
        if (this.isTourRunning()) {
          return;
        }
        event.stopPropagation();
        this.stellarObject = stellarObject;
      },
      isStellarObjectHighlighted(stellarObject) {
        return this.highlightedStellarObjects.includes(stellarObject.id);
      },
      isOreHighlighted(ore) {
        return this.highlightedOres.includes(ore.id);
      },
      toggleHighlightedStellarObject(stellarObject) {
        if (this.isTourRunning()) {
          return;
        }
        const index = this.highlightedStellarObjects.findIndex(id => id === stellarObject.id);
        if (index > -1) {
          this.highlightedStellarObjects.splice(index, 1);
        } else {
          this.highlightedStellarObjects.push(stellarObject.id);
        }
      },
      toggleHighlightedOre(ore) {
        if (this.isTourRunning()) {
          return;
        }
        const index = this.highlightedOres.findIndex(id => id === ore.id);
        if (index > -1) {
          this.highlightedOres.splice(index, 1);
        } else {
          this.highlightedOres.push(ore.id);
        }
      },
      renderOreAppearance(stellarObject, ore) {
        return this.oreAppearances.find(oreAppearance => oreAppearance.stellarObjectId === stellarObject.id && oreAppearance.oreId === ore.id);
      },
      refreshDelayed(delay) {
        if (this.isTourRunning()) {
          return;
        }
        setTimeout(this.refresh, delay);
      },
      async refreshStellarObjects() {
        if (this.isTourRunning()) {
          return;
        }
        this.loading = true;
        await apiSecured.get('/ingame/world/stellarobject', {
          params: {
            pageSize: 1000,
            orderBy: 'name'
          }
        }).then((res) => {
          this.stellarObjects = res.data.filter(record => record.name !== 'Helios').map(record => {
            record.parent = res.data.find(potParent => potParent.id === record.parentId);
            return record;
          });
          this.loading = false;
          this.showInfoNotification('Stellar objects loaded');
        }).catch((error) => {
          this.loading = false;
          this.showErrorNotifications(error)
        });
      },
      async refreshOres() {
        if (this.isTourRunning()) {
          return;
        }
        this.loading = true;
        let oreGroup = null;
        await apiSecured.get('/ingame/items/group/by-name/ore').then(res => {
          oreGroup = res.data;
        }).catch(error => {
          this.loading = false;
          this.showErrorNotifications(error);
        });
        if (oreGroup !== null) {
          await apiSecured.get('/ingame/items/item', {
            params: {
              pageSize: 1000,
              groupIds: oreGroup.id,
              recursiveGroupsFilter: true,
              orderBy: 'name asc',
              fields: 'id,name,icon,tier,scannerColor'
            }
          }).then((res) => {
            this.ores = res.data;
            this.loading = false;
            this.showInfoNotification('Ores loaded');
          }).catch((error) => {
            this.loading = false;
            this.showErrorNotifications(error);
          });
        }
      },
      async refreshOreAppearances() {
        if (this.isTourRunning()) {
          return;
        }
        this.loading = true;
        const stellarObjectIds = this.stellarObjects.map(stellarObject => stellarObject.id);
        await apiSecured.get(`/ingame/world/oreappearance/by-stellar-objects/(${stellarObjectIds.join(',')})`).then((res) => {
          this.oreAppearances = res.data;
          this.loading = false;
          this.showInfoNotification('Ore appearances loaded');
        }).catch((error) => {
          this.loading = false;
          this.showErrorNotifications(error)
        });
      },
      async refresh() {
        if (this.isTourRunning()) {
          return;
        }
        await this.refreshStellarObjects();
        await this.refreshOres();
        await this.refreshOreAppearances();
      },
      tourOnStart() {
        _paq.push(['trackEvent', 'Tour', 'Start', 'OreAppearances']);
      },
      tourOnPreviousStep(currentStep) {
        _paq.push(['trackEvent', 'Tour', 'Prev', 'OreAppearances', currentStep]);
      },
      tourOnNextStep(currentStep) {
        _paq.push(['trackEvent', 'Tour', 'Next', 'OreAppearances', currentStep]);
      },
      tourOnSkip() {
        _paq.push(['trackEvent', 'Tour', 'Skip', 'OreAppearances']);
      },
      tourOnFinish() {
        _paq.push(['trackEvent', 'Tour', 'Finish', 'OreAppearances']);
      }
    },
    created() {
      this.tour = {
        options: {
          labels: {
            buttonSkip: this.__('skip'),
            buttonPrevious: this.__('previous'),
            buttonNext: this.__('next'),
            buttonStop: this.__('finish'),
          }
        },
        steps: [
          {
            target: '#ore-5',
            header: {
              title: this.__('tourTitleOre'),
            },
            content: this.__('tourContentOre'),
            params: tourStepParams
          },
          {
            target: '#stellar-object-0',
            header: {
              title: this.__('tourTitleStellarObject'),
            },
            content: this.__('tourContentStellarObject'),
            params: tourStepParams
          },
          {
            target: '#stellar-object-info-0',
            header: {
              title: this.__('tourTitleMoreInfo'),
            },
            content: this.__('tourContentStellarObjectDetails'),
            params: tourStepParams
          },
          {
            target: '#report',
            header: {
              title: this.__('tourTitleBugDiscovery'),
            },
            content: this.__('tourContentReportOreAppearance'),
            params: tourStepParams
          },
        ],
        callbacks: {
          onStart: this.tourOnStart,
          onPreviousStep: this.tourOnPreviousStep,
          onNextStep: this.tourOnNextStep,
          onSkip: this.tourOnSkip,
          onFinish: this.tourOnFinish
        }
      }
      this.setPageTitle(this.__('entity.common.ore_appearances'));
      this.refresh();
    }
  }
</script>
