<template>
  <div class="stellar-object__stars" ref="sky"></div>
</template>

<script>

export default {
  name: "StarsInTheSky",
  data: () => ({
    sky: null,
    stars: 300,
    size: 3,
  }),
  methods: {
    createDiv(size) {
      let circle = document.createElement('div');
      circle.classList.add('star__circle');

      let randRange5 = Math.floor(Math.random() * 5) + 1;
      circle.classList.add(`star__blink_${randRange5}`);

      let widthAndHeight = this.random(size, 'px');
      circle.style.height = circle.style.width = widthAndHeight;

      circle.style.left = this.random(this.sky.clientWidth, 'px');
      circle.style.top = this.random(this.sky.clientHeight, 'px');

      this.sky.appendChild(circle);
    },
    paintStars(stars, size) {
      while (this.sky.firstChild) {
        this.sky.removeChild(this.sky.firstChild);
      }
      for (let i = 0; i < stars; i++) {
        this.createDiv(size);
      }
    },
    random(range, unit) {
      let randNum = Math.floor(Math.random() * range) + 1;
      return `${randNum}${unit}`;
    },
  },
  mounted() {
    this.sky = this.$refs['sky'];

    this.paintStars(this.stars, this.size);
  }
}
</script>