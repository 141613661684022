<template>
  <div class="stellar-object" id="stellar_object" :key="stellarObject ? stellarObject.id : null">
    <div class="stellar-object__view" v-if="stellarObject">
      <StarsInTheSky/>
      <div class="stellar-object__atmosphere" v-if="stellarObject.hasAtmosphere"></div>
      <img class="stellar-object__mountains" src="https://cdn.hyperion-corporation.de/ui/stellar-object/Mountains.svg" alt="mountains"/>
      <img class="stellar-object__surface" src="https://cdn.hyperion-corporation.de/ui/stellar-object/Surface.svg" alt="surface"/>
      <img class="stellar-object__water stellar-object__water--1" src="https://cdn.hyperion-corporation.de/ui/stellar-object/Water.svg" alt="water" v-if="hasWater"/>
      <img class="stellar-object__water stellar-object__water--2" src="https://cdn.hyperion-corporation.de/ui/stellar-object/Water.svg" alt="water" v-if="hasWater"/>
      <div class="stellar-object__name" v-if="stellarObject">{{stellarObject.name}}</div>
      <div class="stellar-object__system" v-if="stellarObject" :style="systemStyle">{{stellarObject.parent.name}}</div>

      <div class="stellar-object__indicator stellar-object__indicator--water-level" v-if="hasWater">
        <div class="stellar-object__indicator-label">{{__('waterLevel')}}</div>
        <div class="stellar-object__indicator-value">{{ stellarObject.waterLevel }}</div>
      </div>
      <div class="stellar-object__indicator stellar-object__indicator--surface-min-altitude">
        <div class="stellar-object__indicator-label">{{__('surfaceMinAltitude')}}</div>
        <div class="stellar-object__indicator-value" v-html="numberToLocaleString(stellarObject.surfaceMinAltitude,0,0)"></div>
      </div>
      <div class="stellar-object__indicator stellar-object__indicator--surface-average-altitude">
        <div class="stellar-object__indicator-label">{{__('surfaceAverageAltitude')}}</div>
        <div class="stellar-object__indicator-value" v-html="numberToLocaleString(stellarObject.surfaceAverageAltitude,0,0)"></div>
      </div>
      <div class="stellar-object__indicator stellar-object__indicator--surface-max-altitude">
        <div class="stellar-object__indicator-label">{{__('surfaceMaxAltitude')}}</div>
        <div class="stellar-object__indicator-value" v-html="numberToLocaleString(stellarObject.surfaceMaxAltitude,0,0)"></div>
      </div>

      <div class="stellar-object__indicator stellar-object__indicator--anti-grav-min-altitude">
        <div class="stellar-object__indicator-label">{{__('antiGravMinAltitude')}}</div>
        <div class="stellar-object__indicator-value" v-html="numberToLocaleString(stellarObject.antiGravMinAltitude,0,0)"></div>
      </div>

      <div class="stellar-object__indicator stellar-object__indicator--full-atmospheric-density-max-altitude" v-if="stellarObject.hasAtmosphere">
        <div class="stellar-object__indicator-label">{{__('fullAtmosphericDensityMaxAltitude')}}</div>
        <div class="stellar-object__indicator-value" v-html="numberToLocaleString(stellarObject.fullAtmosphericDensityMaxAltitude,0,0)"></div>
      </div>
      <div class="stellar-object__indicator stellar-object__indicator--space-engine-min-altitude" v-if="stellarObject.hasAtmosphere">
        <div class="stellar-object__indicator-label">{{__('spaceEngineMinAltitude')}}</div>
        <div class="stellar-object__indicator-value" v-html="numberToLocaleString(stellarObject.spaceEngineMinAltitude,0,0)"></div>
      </div>
      <div class="stellar-object__indicator stellar-object__indicator--atmospheric-engine-max-altitude" v-if="stellarObject.hasAtmosphere">
        <div class="stellar-object__indicator-label">{{__('atmosphericEngineMaxAltitude')}}</div>
        <div class="stellar-object__indicator-value" v-html="numberToLocaleString(stellarObject.atmosphericEngineMaxAltitude,0,0)"></div>
      </div>
      <div class="stellar-object__indicator stellar-object__indicator--no-atmospheric-density-altitude" v-if="stellarObject.hasAtmosphere">
        <div class="stellar-object__indicator-label">{{__('noAtmosphericDensityAltitude')}}</div>
        <div class="stellar-object__indicator-value" v-html="numberToLocaleString(stellarObject.noAtmosphericDensityAltitude,0,0)"></div>
      </div>

      <div class="stellar-object__indicator stellar-object__indicator--safe-area-edge-altitude">
        <div class="stellar-object__indicator-label">{{__('safeAreaEdgeAltitude')}}</div>
        <div class="stellar-object__indicator-value" v-html="numberToLocaleString(stellarObject.safeAreaEdgeAltitude / 200000,2,2) + ' su'"></div>
      </div>
    </div>
    <div class="bodytext" v-if="stellarObject">
      <div class="tile">
        <p>{{ stellarObject.description }}</p>
      </div>
    </div>
    <div class="stellar-object__data-table">
      <div class="stellar-object__data-property">
        <div class="stellar-object__data-label">{{__('gravity')}}</div>
        <div class="stellar-object__data-value" v-html="numberToLocaleString(stellarObject.gravity,2,2) + 'g'"></div>
      </div>
      <div class="stellar-object__data-property">
        <div class="stellar-object__data-label">{{__('biosphere')}}</div>
        <div class="stellar-object__data-value">{{ stellarObject.biosphere }}</div>
      </div>
      <div class="stellar-object__data-property">
        <div class="stellar-object__data-label">{{__('classification')}}</div>
        <div class="stellar-object__data-value">{{ stellarObject.classification }}</div>
      </div>
      <div class="stellar-object__data-property">
        <div class="stellar-object__data-label">{{__('habitability')}}</div>
        <div class="stellar-object__data-value">{{ stellarObject.habitability }}</div>
      </div>
      <div class="stellar-object__data-property">
        <div class="stellar-object__data-label">{{__('numSatellites')}}</div>
        <div class="stellar-object__data-value">{{ stellarObject.numSatellites }}</div>
      </div>
      <div class="stellar-object__data-property">
        <div class="stellar-object__data-label">{{__('radius')}}</div>
        <div class="stellar-object__data-value" v-html="numberToLocaleString(stellarObject.radius,0,0) + 'km'"></div>
      </div>
      <div class="stellar-object__data-property">
        <div class="stellar-object__data-label">{{__('size')}}</div>
        <div class="stellar-object__data-value">{{ stellarObject.size }}</div>
      </div>
      <div class="stellar-object__data-property">
        <div class="stellar-object__data-label">{{__('surfaceArea')}}</div>
        <div class="stellar-object__data-value" v-html="numberToLocaleString(stellarObject.surfaceArea,0,0) + 'km²'"></div>
      </div>
      <div class="stellar-object__data-property">
        <div class="stellar-object__data-label">{{__('territories')}}</div>
        <div class="stellar-object__data-value" v-html="numberToLocaleString(stellarObject.territories,0,0) + 'km²'"></div>
      </div>
      <div class="stellar-object__data-property">
        <div class="stellar-object__data-label">{{__('type')}}</div>
        <div class="stellar-object__data-value">{{ stellarObject.type }}</div>
      </div>
    </div>
  </div>
</template>

<script>

import StarsInTheSky from "@/components/Ingame/StellarObject/StarsInTheSky";
import { localizationMixin } from "@/mixins/localization";
import { numberToLocaleString } from "@/helpers/itemHelpers";
export default {
  name: 'StellarObjectDetails',
  mixins: [localizationMixin],
  components: { StarsInTheSky },
  props: ['stellarObject'],
  computed: {
    hasWater() {
      return ![undefined, null].includes(this.stellarObject.waterLevel);
    },
    systemStyle() {
      return {
        backgroundImage: `url("${this.stellarObject.parent.icon}")`,
      }
    }
  },
  methods: {
    numberToLocaleString,
  }
}
</script>